.header-content{
    min-height: 75vh;
    background: linear-gradient(rgba(15, 51, 2, 0.3), rgba(12, 57, 1, 0.5)), url("../../images/bookstore1.jpg") center/cover no-repeat;
    flex-direction: column;
}
.header-title{
    font-size: 4.2rem;
}
.header-text{
    margin-top: -1.8rem;
    margin-bottom: 2.8rem;
    opacity: 0.8;
    max-width: 770px;
}