.navbar{
    padding: 1.8rem 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.navbar-brand{
    width: 90px;
}
.navbar-brand span{
    margin-left: 2rem;
}
.brand-and-toggler{
    width: 100%;
}
.navbar-content{
    display: flex;
    width: 100%;
}
.navbar-toggler-btn{
    transition: var(--transition);
    z-index: 11;
}
.navbar-collapse{
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
    background-color: var(--black-color);
    height: 100%;
    width: 280px;
    display: flex;
    padding: 10rem 3rem 0 3rem;
    transform: translatex(100%);
    transition: var(--transition);
}
.show-navbar-collapse{
    transform: translateX(0);
}
.nav-item{
    margin-bottom: 1.4rem;
}
.nav-link{
    transition: var(--transition);
}
.nav-link:hover{
    color: var(--purple-color);
}

.font-bold {
    font-weight: bold;
    font-size: larger;
}

.text-uppercase {
    display: inline; /* Display inline */
    white-space: nowrap; /* Prevent line break */
}

@media screen and (min-width: 992px){
    .navbar-collapse{
        position: relative;
        height: auto;
        padding: 0;
        width: 100%;
        background-color: transparent;
        transform: translateX(0);
        justify-content: flex-end;
    }
    .navbar-toggler-btn{
        display: none;
    }
    .nav-item{
        margin-bottom: 0;
        margin-left: 2.8rem;
    }
    .nav-link{
        color: var(--black-color);
        font-size: 1.7rem;
        font-weight: 500!important;
        opacity: 0.8;
    }
    .navbar-nav{
        display: flex;
    }

    .font-bold {
        font-weight: bold;
    }
}